import * as actionTypes from '../actions/notifications/actionTypes';

function notifications(state = { growls: [], timeout: 5000 }, action) {
  switch (action.type) {
    case actionTypes.SHOW_GROWL:
      return {
        ...state,
        growls: [
          ...state.growls,
          {
            type: action.payload.type,
            message: action.payload.message,
            id: action.payload.id,
          },
        ],
      };
    case actionTypes.REMOVE_GROWL:
      return {
        ...state,
        growls: state.growls.filter(g => g.id !== action.payload),
      };
    case actionTypes.SET_TIMEOUT:
      return {
        ...state,
        timeout: action.payload,
      };
    default:
      return state;
  }
}

export default notifications;
