module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-accessibilityjs/gatsby-browser.js'),
      options: {"plugins":[],"injectStyles":"\n          .accessibility-error {\n            box-shadow: 0 0 3px 1px #f00;\n            background-color: rgba(255, 0, 0, 0.25);\n            position: relative;\n          }\n          .accessibility-error:before {\n            content: \"A11Y\";\n            position: absolute;\n            top: 0;\n            left: 0;\n            color: #fff;\n            font-size: 10px;\n            background-color: rgba(255, 0, 0, 0.5);\n            transform: translateY(-100%);\n          }\n        "},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KQJ5TJX","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sci-Tech Carpet Cleaning","short_name":"STCC","start_url":"/","background_color":"#500103","theme_color":"#500103","display":"fullscreen","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Lato:400,700","sans-serif"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
