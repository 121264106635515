import assign from 'lodash/assign';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

/**
 * Module to handle client-side authentication requests.
 *
 * This module uses JWT for authentication.
 *
 * Version: 0.0.1
 * @param {Object} opts - Options object to override defaults.
 */
function authentication(opts) {
  const options = assign(
    {
      server: '',
      tokenStorageKey: 'stcc_token',
    },
    opts
  );

  /**
   * Helper function to append a path to the server address.
   * @param {String} path - Route path relative to the server address.
   *
   * @returns {String} The full path to send a request to.
   */
  const _url = path => `${options.server}${path}`;

  /**
   * Helper function to store the token in localStorage.
   *
   * @param {String} token - The JWT.
   */
  const _storeToken = token => {
    typeof window !== 'undefined' &&
      window.localStorage.setItem(options.tokenStorageKey, token);
  };

  /**
   * Helper function to retrieve the stored JWT in localStorage.
   *
   * @returns {String} The JWT.
   */
  const _getStoredToken = () => {
    if (typeof window !== 'undefined') {
      return window.localStorage.getItem(options.tokenStorageKey);
    } else {
      return null;
    }
  };

  /**
   * Helper function to remove the JWT from localStorage.
   */
  const _removeToken = () => {
    typeof window !== 'undefined' &&
      window.localStorage.removeItem(options.tokenStorageKey);
  };

  return {
    /**
     * Creates a new account.
     *
     * @param {Object} user - The user object.  This object must contain email, password, firstName, and lastName.
     *
     * @returns {Object} The user object.
     */
    createAccount: function({ email, password, firstName, lastName }) {
      return axios
        .post(_url('/users'), {
          email,
          password,
          firstName,
          lastName,
        })
        .then(res => {
          if (res.data.user.token) {
            _storeToken(res.data.user.token);
            return res.data.user;
          }
        })
        .catch(err => {
          console.error('An error occurred:', err);
        });
    },
    /**
     * Accepts an email address and password and attempts to log the user in.
     *
     * @param {Object} credentials - The credentials object containing an email field and a password field.
     *
     * @returns {Object} The user object.
     */
    login: function({ email, password, token }) {
      return axios
        .post(_url('/users/login'), {
          user: {
            email,
            password,
            token,
          },
        })
        .then(res => {
          if (res.data.user.token) {
            _storeToken(res.data.user.token);
            return res.data.user;
          }
        })
        .catch(err => {
          console.error('An error occurred:', err);
        });
    },
    loginAnonymously: function() {
      if (this.hasToken() && !this.isTokenExpired()) {
        return new Promise((resolve, reject) => {
          resolve(this.decodeToken());
        });
      }

      if (this.hasToken() && this.isTokenExpired()) {
        this.logout();
      }

      return axios
        .post(_url('/users/login-anonymous'))
        .then(res => {
          if (res.data.user.token) {
            _storeToken(res.data.user.token);
            return res.data.user;
          }
        })
        .catch(err => {
          console.error('An error occurred:', err);
        });
    },
    /**
     *
     * @param {Object} loginObject The login object containing an email field.
     */
    getEmailToken: function({ email }) {
      return axios.post(_url('/users/email-token'), {
        email,
      });
    },
    changePassword: function({ userId, currentPassword, newPassword }) {
      if (!userId) {
        throw new Error('No user ID supplied.');
      }

      if (!currentPassword) {
        throw new Error('Current password not supplied.');
      }

      if (!newPassword) {
        throw new Error('New password not supplied.');
      }

      return axios.post(
        _url(`/users/${userId}/change-password`),
        {
          currentPassword: currentPassword,
          newPassword: newPassword,
        },
        {
          headers: {
            authorization: `Token ${_getStoredToken()}`,
          },
        }
      );
    },
    /**
     * Attempts to get the currently logged in user from the server.
     */
    getCurrentUser: function() {
      return axios.get(_url('/users/current'), {
        headers: {
          authorization: `Token ${_getStoredToken()}`,
        },
      });
    },
    /**
     * Gets the logged in status.
     *
     * @returns {boolean} true if logged in and false otherwise.
     */
    hasToken: function() {
      return !!_getStoredToken();
    },
    /**
     * Gets the stored JWT.
     *
     * @returns {String} the stored JWT.
     */
    getToken: function() {
      return _getStoredToken();
    },
    decodeToken: function() {
      return jwtDecode(this.getToken());
    },
    isTokenExpired: function() {
      if (this.hasToken()) {
        const user = this.decodeToken();
        return Date.now() / 1000 > user.exp;
      } else {
        return true;
      }
    },
    /**
     * Logs the user out by deleting the stored JWT.
     */
    logout: function() {
      return new Promise((resolve, reject) => {
        _removeToken();
        resolve();
      });
    },
  };
}

export default authentication;
