import Authentication from '../../../util/authentication';

import { SET_USER, SET_LOADING } from './actionTypes';

import config from '../../../config';

const authentication = Authentication({
  server: config.backendServer,
});

// Set the user state.
function setUser(user, token) {
  return {
    type: SET_USER,
    payload: {
      user,
      token,
    },
  };
}

function setLoading(isLoading) {
  return {
    type: SET_LOADING,
    payload: isLoading,
  };
}

// Login the user.
function login({ email, password, token }) {
  return dispatch => {
    dispatch(setLoading(true));
    return authentication.login({ email, password, token }).then(
      user => {
        dispatch(setUser(user, authentication.getToken()));
        dispatch(setLoading(false));
        return user;
      },
      err => {
        console.error(err);
        dispatch(setLoading(false));
      }
    );
  };
}

// Anonymous login
function loginAnonymously() {
  return dispatch => {
    return authentication.loginAnonymously().then(
      user => {
        dispatch(setUser(user, authentication.getToken()));
        return user;
      },
      err => {
        console.error(err);
      }
    );
  };
}

function logout() {
  return dispatch => {
    dispatch(setLoading(true));
    authentication.logout().then(() => {
      dispatch(setUser(null, null));
      dispatch(setLoading(false));
    });
  };
}

function getEmailToken(email) {
  return dispatch => {
    dispatch(setLoading(true));
    authentication.getEmailToken({ email }).then(() => {
      dispatch(setLoading(false));
    });
  };
}

function changePassword({ userId, newPassword, currentPassword }) {
  return dispatch => {
    dispatch(setLoading(true));
    return authentication
      .changePassword({
        userId,
        newPassword,
        currentPassword,
      })
      .then(res => {
        dispatch(setLoading(false));
        return res;
      });
  };
}

// Sets user if JWT already exists and is valid.
function tokenExists() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (authentication.hasToken() && authentication.isTokenExpired()) {
        logout();
        dispatch(setUser(null, null));
        resolve(null);
      } else if (
        authentication.hasToken() &&
        !authentication.isTokenExpired()
      ) {
        dispatch(
          setUser(authentication.decodeToken(), authentication.getToken())
        );
        resolve(authentication.decodeToken());
      } else {
        dispatch(setUser(null, null));
        resolve(null);
      }
    });
  };
}

export {
  setUser,
  setLoading,
  login,
  loginAnonymously,
  logout,
  getEmailToken,
  changePassword,
  tokenExists,
};
