const VPSENV = 'https://api.scitechcarpetcleaning.com/api/v1';
const LOCALENV = 'http://localhost:3000/api/v1';

export default {
  backendServer: process.env.NODE_ENV === 'production' ? VPSENV : LOCALENV,
  backendActive: true,
  phoneNumber: '540-381-7847',
  phoneNumberHref: 'tel:1-540-381-7847',
  enableBlogGiveaway: false,
};
