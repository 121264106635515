import {
  createStore as reduxCreateStore,
  combineReducers,
  compose,
  applyMiddleware,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import * as reducers from './reducers';
import logger from '../redux/middlewares/logger';

const reducer = combineReducers(reducers);

const initialState = {
  authentication: {
    user: null,
    token: null,
  },
  notifications: {
    growls: [],
    timeout: 5000,
  },
};

// let createStore = () => {};

// try {
// const composeEnhancers = window
//   ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//   : compose;
let composeEnhancers;
if (typeof window !== 'undefined') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
} else {
  composeEnhancers = compose;
}

const createStore = () =>
  reduxCreateStore(
    reducer,
    initialState,
    composeEnhancers
      ? composeEnhancers(applyMiddleware(thunkMiddleware, logger))
      : applyMiddleware(thunkMiddleware, logger)
  );
// } catch (e) {}

export default createStore;
