import { SET_USER, SET_LOADING } from '../actions/authentication/actionTypes';

function authentication(state = {}, action) {
  switch (action.type) {
    case SET_USER:
      return Object.assign({}, state, {
        user: action.payload.user,
        token: action.payload.token,
      });
    case SET_LOADING:
      return Object.assign({}, state, { isLoading: action.payload });
    default:
      return state;
  }
}

export default authentication;
