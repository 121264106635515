const logger = store => next => action => {
  if (process.env.NODE_ENV === 'production') {
    next(action);
    return;
  }

  console.group(action.type);
  console.log(action.payload);
  console.groupEnd();
  next(action);
};

export default logger;
